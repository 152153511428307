/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WinBloggers from "./pages/WinBloggers";

function RootRouter() {
	const router = createBrowserRouter([
		{
			path: "/win-bloggers",
			element: <WinBloggers />,
		},
		{
			path: "/",
			element: <></>,
		},
	]);

	return <RouterProvider router={router} />;
}

export default RootRouter;
